import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { PaperStyles } from "components/Paper/index.style";
import Spinner from "components/Spinner";
import VendorHero from "components/VendorHero";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "router/paths";
import { breakpoints } from "styles/utils";

const HomeCard = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1fr;
    gap: 128px;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 40px;
  margin-bottom: 16px;
`;

const ContainerHero = styled.div`
  @media (max-width: ${breakpoints.large}) {
    margin-top: 32px;
    padding: 0px 30px;
  }
`;

const ContainerLaodingPage = styled(PaperStyles)`
  margin: 24px 0px;
  padding: 20px 0px;
  border-radius: 12px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 30px 0px;
  }
`;

const HeaderCard = styled.div<{ backgroundColor?: string | undefined }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  padding: 8px 0px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 20px 0px;
  }
`;

const BodyCard = styled.div`
  padding: 16px 24px;
`;

const BodyCardTitle = styled(Typography)`
  color: #000;
  font-weight: 700;
`;

const BodyCardSubtitle = styled(Typography)`
  color: #000;
  font-weight: 500;
`;

const WrapperInnerCard = styled(PaperStyles)`
  padding: 32px 16px !important;
  margin-top: 35px !important;

  @media (min-width: ${breakpoints.large}) {
    padding: 50px 60px !important;
  }
`;

const WrapperNoProduct = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 50px 16px;
  @media (min-width: ${breakpoints.large}) {
    justify-content: center;
    margin: 85px auto;
    max-width: 800px;
  }
`;

const BannerImageContainer = styled.picture`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  @media (min-width: ${breakpoints.large}) {
    margin-top: 0px;
  }
`;

const BannerImg = styled.img`
  width: 268px;
  height: 269px;
  @media (min-width: 601px) {
    width: 536px;
    height: 538px;
  }
`;

function Home() {
  const { vendorTheme } = useContext(VendorThemeContext);
  const navigate = useNavigate();

  const handleInstallNow = () => {
    navigate(LOGIN);
  };

  if (!vendorTheme || vendorTheme?.isDefaultTheme) {
    return (
      <ContainerLaodingPage noPadding noMargin elevation={2}>
        <HeaderCard backgroundColor={vendorTheme?.primaryColor}>
          <Typography
            variant="body2"
            align="center"
            variantMapping={{
              h4: "h1",
            }}
            style={{
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            Aguarde um instante...
          </Typography>
        </HeaderCard>
        <BodyCard>
          <BodyCardTitle variant="h2" align="center">
            Estamos subindo as informações de seu provedor
          </BodyCardTitle>

          <WrapperInnerCard noPadding noMargin backgroundColor="#F5F5F5">
            <WrapperNoProduct>
              <BodyCardSubtitle variant="h2" align="center">
                <Spinner size={50} />
              </BodyCardSubtitle>
              <BodyCardSubtitle variant="h2" align="center">
                Em breve terá acesso ao login.
              </BodyCardSubtitle>
              <BodyCardSubtitle variant="h2" align="center">
                Caso tenha alguma dúvida, entre em contato com nosso suporte:{" "}
                <div>
                  <a href="mailto:queroumantivirus@itailers.com.br">
                    queroumantivirus@itailers.com.br
                  </a>
                </div>
              </BodyCardSubtitle>
              <BodyCardSubtitle variant="h2" align="center">
                Ou entre em contato com nosso chat.
              </BodyCardSubtitle>
            </WrapperNoProduct>
          </WrapperInnerCard>
        </BodyCard>
      </ContainerLaodingPage>
    );
  }

  return (
    <PaperStyles backgroundColor={vendorTheme?.secondaryColor}>
      <HomeCard>
        <BannerImageContainer>
          <source
            media="(max-width: 600px)"
            srcSet={vendorTheme?.bannerImageMobile}
          />
          <source
            media="(min-width: 601px)"
            srcSet={vendorTheme?.bannerImage}
          />
          <BannerImg src={vendorTheme?.bannerImage} alt="banner do provedor" />
        </BannerImageContainer>

        <ContainerHero>
          <VendorHero />
          <Typography
            variant="h1"
            gutterBottom
            style={{ fontWeight: 600, marginTop: 35 }}
          >
            Garantia da melhor e mais premiada proteção do mundo
          </Typography>
          <Typography variant="h2">
            Junte-se a mais de 400 milhões de usuários protegidos pela
            Kaspersky.
          </Typography>
          <ButtonStyled
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleInstallNow}
          >
            Instale Agora
          </ButtonStyled>
          <Typography variant="caption">
            Windows® | macOS® | Android™
          </Typography>
        </ContainerHero>
      </HomeCard>
    </PaperStyles>
  );
}

export default Home;
