import { Theme } from "@mui/material";
import { GlobalStyles } from "@mui/material";

const GlobalCss = ({ theme }: { theme: Theme }) => {
  const globalStyle = `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // outline: 1px solid red !important;
    // background: rgb(0 100 0 / 0.1) !important;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }
  
    html {
      font-size: 62.5%;
    }

    html,
    body,
    #root {
      height: 100%;
      width: 100%;
    }

    body {
      background-color: #fff;
    }

    a { color: inherit; text-decoration: none;} 

    ul {
      list-style:none;
    }

    ul, li { /* reset the margin and padding */
      margin: 0;
      padding: 0;
   }
`;

  return <GlobalStyles styles={globalStyle} />;
};

export default GlobalCss;
