import { useContext } from "react";

import { VendorThemeContext } from "contexts/vendorContext";
import { breakpoints } from "styles/utils";
import styled from "@emotion/styled";

const LogoImage = styled.img`
  height: 44px;
  @media (min-width: ${breakpoints.medium}) {
    height: 52px;
  }
`;

const VendorLogo = () => {
  const { vendorTheme } = useContext(VendorThemeContext);
  return (
    <LogoImage
      loading="lazy"
      alt="imagem com o logo do provedor"
      src={vendorTheme?.logoImage}
    />
  );
};
export default VendorLogo;
