import styled from "@emotion/styled";

export type ContentProps = {
  children: React.ReactNode;
  className?: string | undefined;
};

const ContentStyles = styled.section`
  margin: 0 auto;
  max-width: 1240px;
`;

const Content = ({ children, className }: ContentProps) => {
  return <ContentStyles className={className}>{children}</ContentStyles>;
};
export default Content;
