import styled from "@emotion/styled";
import { breakpoints } from "styles/utils";

const HiddenOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.large}) {
    display: block;
  }
`;

export default HiddenOnDesktop;
