import { createTheme } from "@mui/material/styles";
import { breakpoints } from "styles/utils";

export type VendorTheme = {
  vendorDomainName?: string;
  primaryColor: string;
  secondaryColor: string;
  logoImage: string;
  vendorTitleImage: string;
  bannerImage: string;
  bannerImageMobile?: string;
  isDarkTheme: boolean;
  id_vendor_theme?: number;
  id_cliente?: number;
  kl_token?: string;
  //internal usage - loading page
  isDefaultTheme?: boolean;
};

const getColorByThemeMode = (isDark: boolean) => {
  return isDark ? "#fff" : "rgba(0, 0, 0, 0.87)";
};

export const getVendorTheme = (vendorTheme: VendorTheme) => {
  const theme = createTheme({
    palette: {
      // configure dak mode https://mui.com/material-ui/customization/dark-mode/#dark-mode-with-a-custom-palette
      ...(vendorTheme.isDarkTheme
        ? {
            text: {
              primary: "#fff",
              secondary: "rgba(255, 255, 255, 0.7)",
              disabled: "rgba(255, 255, 255, 0.5)",
            },
          }
        : {
            text: {
              primary: "rgba(0, 0, 0, 0.87)",
              secondary: "rgba(0, 0, 0, 0.6)",
              disabled: "rgba(0, 0, 0, 0.38)",
            },
          }),
      primary: {
        main: vendorTheme.primaryColor,
        contrastText: getColorByThemeMode(vendorTheme.isDarkTheme),
      },
      secondary: {
        main: vendorTheme.secondaryColor,
        contrastText: getColorByThemeMode(vendorTheme.isDarkTheme),
      },
    },
    typography: {
      htmlFontSize: 10,
      h1: {
        fontSize: "1.6rem",
        fontWeight: 600,
        [`@media (min-width:${breakpoints.medium})`]: {
          fontSize: "2.4rem",
        },
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      h2: {
        fontSize: "1.4rem",
        fontWeight: 400,
        [`@media (min-width:${breakpoints.medium})`]: {
          fontSize: "2.0rem",
        },
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      h3: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      h4: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      h5: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      h6: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
        fontWeight: 300,
      },
      subtitle1: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      subtitle2: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      body1: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      body2: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
        fontSize: "1.4rem",
        fontWeight: 600,
        [`@media (min-width:${breakpoints.medium})`]: {
          fontSize: "2rem",
        },
      },
      button: {
        fontSize: "1.4rem",
        fontWeight: 400,
        [`@media (min-width:${breakpoints.medium})`]: {
          fontSize: "1.6rem",
        },
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      caption: {
        fontSize: "1.2rem",
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
      overline: {
        //color: vendorTheme.isDarkTheme ? "#fff" : "rgba(0, 0, 0, 0.87)",
      },
    },
  });
  return theme;
};
