import { createContext } from "react";
import { VendorTheme } from "theme";

export type VendorValues = {
  vendorTheme?: VendorTheme | undefined;
  setVendorTheme: (signupEmail: VendorValues["vendorTheme"]) => void;
};

const VendorThemeContext = createContext<VendorValues>({
  vendorTheme: undefined,
  setVendorTheme: () => {},
});

VendorThemeContext.displayName = "VendorThemeContext";

export { VendorThemeContext };
