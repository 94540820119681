import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { breakpoints } from "styles/utils";

export const BodyCardTitle = styled(Typography)`
  color: #000;
  font-weight: 700;
  margin-bottom: 36px;
`;

export const BodyCardSubtitle = styled(Typography)`
  color: #000;
  font-weight: 500;
  @media (min-width: ${breakpoints.medium}) {
    font-size: 2rem;
  }
`;
