import axios from "axios";
import { VendorTheme } from "theme";
import configs from "config";
import { saveItemSessionStorage, THEME_PROVIDER } from "utils/helpers";

const getTheme = async (vendorName: string): Promise<VendorTheme> => {
  const defaultThemeVendor: VendorTheme = {
    primaryColor: "#606060",
    secondaryColor: "#FFFFFF",
    logoImage: "",
    bannerImage: "",
    bannerImageMobile: "",
    vendorTitleImage: "",
    isDarkTheme: false, // When true text color should be white
    isDefaultTheme: true,
  };

  try {
    const response = await axios.get(
      `${configs.baseURL}/GetProviderInfo?provedor=${vendorName}`
    );

    saveItemSessionStorage(`${THEME_PROVIDER}_${vendorName.toUpperCase()}`, {
      ...response.data,
    });

    return {
      ...response.data,
    };
  } catch (error) {
    return defaultThemeVendor;
  }
};

export { getTheme };
