import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import {
  BodyCardSubtitle,
  BodyCardTitle,
} from "components/CardTypography/styles";

import { PaperStyles } from "components/Paper/index.style";
import ProductActivatedCard from "components/ProductActivatedCard";
import Spinner from "components/Spinner";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext, useEffect, useState } from "react";
import { AuthProps } from "services/login";
import { getProducts, ProductProps } from "services/products";
import { breakpoints } from "styles/utils";
import { AUTH_USER, getItemSessionStorage } from "utils/helpers";

const ContainerCard = styled(PaperStyles)`
  padding: 20px 0px;
  border-radius: 12px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 30px 0px;
  }
`;

const HeaderCard = styled.div<{ backgroundColor?: string | undefined }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  padding: 8px 0px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 20px 0px;
  }
`;

const BodyCard = styled.div`
  padding: 24px 16px;
  @media (min-width: ${breakpoints.large}) {
    padding-right: 70px;
    padding-left: 70px;
  }
`;

const WrapperInnerCard = styled(PaperStyles)`
  padding: 24px 16px;
  margin: 24px 0px !important;

  @media (min-width: ${breakpoints.medium}) {
    padding: 30px;
  }

  @media (min-width: ${breakpoints.large}) {
    padding: 50px;
  }
`;

const WrapperNoProduct = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin: 50px 16px;
  @media (min-width: ${breakpoints.large}) {
    justify-content: center;
    margin: 85px auto;
    max-width: 800px;
  }
`;

function MyAccount() {
  const { vendorTheme } = useContext(VendorThemeContext);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState<ProductProps[] | []>([]);
  const auth = getItemSessionStorage(
    `${AUTH_USER}_${vendorTheme?.vendorDomainName}`
  ) as AuthProps;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // You can await here

      if (vendorTheme?.kl_token) {
        const products = (await getProducts(
          auth.userId,
          vendorTheme?.kl_token!
        )) as ProductProps[];

        if (products.length > 0) {
          const filteredActivatedProducts = products.filter(
            (item) => item.activated === true
          );
          setProducts(filteredActivatedProducts);
        } else {
          setProducts([]);
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [auth.userId, vendorTheme, vendorTheme?.kl_token]);

  const renderActivitadeProducts = (products: ProductProps[]) => {
    if (!products.length) {
      return null;
    }
    return products.map((item) => {
      return (
        <WrapperInnerCard
          key={item.productId}
          noPadding
          noMargin
          backgroundColor="#F5F5F5"
        >
          <ProductActivatedCard {...item} />
        </WrapperInnerCard>
      );
    });
  };

  const renderedActivitadeProducts = renderActivitadeProducts(products);

  if (loading) {
    return (
      <ContainerCard noPadding elevation={2}>
        <div style={{ margin: "100px auto" }}>
          <Typography
            variant="h1"
            style={{ color: "#000", display: "flex", justifyContent: "center" }}
            align="center"
          >
            <Spinner size={80} />
          </Typography>
        </div>
      </ContainerCard>
    );
  }

  return (
    <ContainerCard noPadding elevation={2}>
      <HeaderCard backgroundColor={vendorTheme?.primaryColor}>
        <Typography
          variant="body2"
          align="center"
          variantMapping={{
            h4: "h1",
          }}
          style={{
            fontWeight: "bold",
          }}
        >
          QueroUmAntivírus / Minha Conta
        </Typography>
      </HeaderCard>
      <BodyCard>
        <BodyCardTitle variant="h1">
          Aqui você encontra o melhor serviço de segurança para os seus
          dipositivos: PC, celular e tablet.
        </BodyCardTitle>
        <BodyCardSubtitle variant="h1">
          Escolha o produto que deseja instalar nos seus dispositivos e clique
          em "Ativar".
        </BodyCardSubtitle>
        <BodyCardSubtitle variant="h1">
          É fácil e rápido! Veja as instruções ao lado de cada produto.
        </BodyCardSubtitle>

        {renderedActivitadeProducts ? (
          renderedActivitadeProducts
        ) : (
          <WrapperInnerCard noPadding noMargin backgroundColor="#F5F5F5">
            <WrapperNoProduct>
              <BodyCardSubtitle align="center">
                No momento você não possui nenhum produto ativo em nosso
                sistema.
              </BodyCardSubtitle>
              <BodyCardSubtitle align="center">
                Volte na página de produtos e selecione o produto desejado. Caso
                tenha alguma dúvida entre em contato com o nosso suporte.
              </BodyCardSubtitle>
              <Button variant="contained">Voltar</Button>
            </WrapperNoProduct>
          </WrapperInnerCard>
        )}
      </BodyCard>
    </ContainerCard>
  );
}

export default MyAccount;
