import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { breakpoints } from "styles/utils";

export type PaperStylesProps = {
  backgroundColor?: string | undefined;
  noPadding?: boolean;
  noMargin?: boolean;
};

const PaperStyles = styled(Paper)<PaperStylesProps>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border-radius: 16px;

  @media (min-width: ${breakpoints.small}) {
    border-radius: 30px;
  }

  ${(props) =>
    props.noMargin
      ? `padding: 0px;`
      : `
    
      margin: 24px 10px;
    
    @media (min-width: ${breakpoints.medium}) {
      margin: 60px 16px;
    }
  `}
  ${(props) =>
    props.noPadding
      ? `padding: 0px;`
      : `
  
    padding: 12px 4px;
  
  @media (min-width: ${breakpoints.medium}) {
    padding: 36px 8px;
  }
  
  `}

  @media (min-width: ${breakpoints.large}) {
    ${(props) => (props.noMargin ? `padding: 0px;` : `margin: 24px 0px;`)}
    ${(props) => (props.noPadding ? `padding: 0px;` : `padding: 86px;`)}
  }
`;

export { PaperStyles };
