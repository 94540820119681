import { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled";
import HiddenOnDesktop from "components/HiddenOnDesktop/index.style";
import HiddenOnMobile from "components/HiddenOnMobile/index.style";
import { PaperStyles } from "components/Paper/index.style";

import { getProducts, ProductProps } from "services/products";
import ProductCard from "components/ProductCard";
import { VendorThemeContext } from "contexts/vendorContext";
import { AUTH_USER, getItemSessionStorage } from "utils/helpers";
import { AuthProps } from "services/login";
import { Typography } from "@mui/material";
import Spinner from "components/Spinner";

const DesktopCardPaper = styled(PaperStyles)`
  padding: calc(56px - 24px) 32px !important;
`;

function Products() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductProps[] | [] | null>(null);
  const { vendorTheme } = useContext(VendorThemeContext);
  const auth = getItemSessionStorage(
    `${AUTH_USER}_${vendorTheme?.vendorDomainName}`
  ) as AuthProps;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // You can await here

      if (vendorTheme?.kl_token) {
        const products = (await getProducts(
          auth.userId,
          vendorTheme?.kl_token!
        )) as ProductProps[];

        if (products.length > 0) {
          setProducts(products);
        } else {
          setProducts([]);
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [auth.userId, vendorTheme, vendorTheme?.kl_token]);

  function renderProducts() {
    if (products?.length) {
      return products.map((item) => {
        return (
          <ProductCard
            key={item.productId}
            productName={item.productName}
            productId={item.productId}
            description={item.description}
            productImage={item.productImage}
            activated={item.activated}
          />
        );
      });
    }
  }

  const renderedProducts = renderProducts();

  if (loading) {
    return (
      <DesktopCardPaper>
        <div style={{ margin: "100px auto" }}>
          <Typography
            variant="h1"
            style={{ color: "#000", display: "flex", justifyContent: "center" }}
            align="center"
          >
            <Spinner size={80} />
          </Typography>
        </div>
      </DesktopCardPaper>
    );
  }

  if (!loading && !products?.length) {
    return (
      <DesktopCardPaper>
        <div style={{ margin: "100px auto" }}>
          <Typography variant="h1" style={{ color: "#000" }} align="center">
            Usuário não tem produtos disponíveis
          </Typography>
        </div>
      </DesktopCardPaper>
    );
  }

  return (
    <>
      <HiddenOnMobile>
        <DesktopCardPaper noPadding>{renderedProducts}</DesktopCardPaper>
      </HiddenOnMobile>
      <HiddenOnDesktop>{renderedProducts}</HiddenOnDesktop>
    </>
  );
}

export default Products;
