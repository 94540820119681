import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import {
  BodyCardSubtitle,
  BodyCardTitle,
} from "components/CardTypography/styles";
import { PaperStyles } from "components/Paper/index.style";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HOME } from "router/paths";
import { breakpoints } from "styles/utils";

const ContainerCard = styled(PaperStyles)`
  padding: 20px 0px;
  border-radius: 12px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 30px 0px;
  }
`;

const HeaderCard = styled.div<{ backgroundColor?: string | undefined }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  padding: 8px 0px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 20px 0px;
  }
`;

const BodyCard = styled.div`
  padding: 16px 24px;
`;

const WrapperInnerCard = styled(PaperStyles)`
  padding: 32px 16px !important;
  margin-top: 35px !important;

  @media (min-width: ${breakpoints.large}) {
    padding: 50px 60px !important;
  }
`;

const WrapperNoProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 16px;
  @media (min-width: ${breakpoints.large}) {
    justify-content: center;
    margin: 85px auto;
    max-width: 800px;
  }
`;

function NotFound() {
  const navigate = useNavigate();
  const { vendorTheme } = useContext(VendorThemeContext);

  return (
    <ContainerCard noPadding elevation={2}>
      <HeaderCard backgroundColor={vendorTheme?.primaryColor}>
        <Typography
          variant="body2"
          align="center"
          variantMapping={{
            h4: "h1",
          }}
          style={{
            fontWeight: "bold",
          }}
        >
          QueroUmAntivírus / Página não encontrada
        </Typography>
      </HeaderCard>
      <BodyCard>
        <BodyCardTitle variant="h1" style={{ textTransform: "uppercase" }}>
          PÁGINA NÃO ENCONTRADA
        </BodyCardTitle>
        <BodyCardSubtitle variant="h1">
          A página que deseja entrar está desabilitada ou não existe.
        </BodyCardSubtitle>
        <WrapperInnerCard noPadding noMargin backgroundColor="#F5F5F5">
          <WrapperNoProduct>
            <Typography style={{ color: "#000", fontWeight: 500 }} variant="h2">
              OPS!
            </Typography>
            <BodyCardSubtitle align="center" variant="h2">
              Desculpe, a página que você está procurando não existe.
              <div>
                Se você acha que algo está quebrado, relate um problema enviando
                um email.
              </div>
            </BodyCardSubtitle>
            <Button
              variant="contained"
              style={{ marginTop: 78 }}
              onClick={() => navigate(HOME)}
            >
              ir para home
            </Button>
          </WrapperNoProduct>
        </WrapperInnerCard>
      </BodyCard>
    </ContainerCard>
  );
}

export default NotFound;
