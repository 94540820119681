import { useContext } from "react";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { VendorThemeContext } from "contexts/vendorContext";
import BaseModal from ".";

const GridProduct = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 8px;
`;

const ProductTitle = styled(Typography)`
  color: #000;
  font-weight: 500;
  align-self: center;
`;

export type ModalInfoMoreProps = {
  open: boolean;
  onClose: () => void;
  productImage: string;
  productName: string;
  description: string;
};

const ModalInfoMore = ({
  open,
  onClose,
  productImage,
  productName,
  description,
}: ModalInfoMoreProps) => {
  const { vendorTheme } = useContext(VendorThemeContext);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={
        <GridProduct>
          <img
            loading="lazy"
            height={96}
            width={105}
            alt="logo kaspersky"
            src={productImage}
          ></img>
          <ProductTitle variant="h6">{productName}</ProductTitle>
        </GridProduct>
      }
      body={
        <div>
          <Typography
            color="primary"
            variant="subtitle1"
            style={{ fontWeight: 400, color: "#000" }}
          >
            {description}
          </Typography>
        </div>
      }
      footer={
        <div style={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            style={{ height: 60 }}
            onClick={onClose}
          >
            Voltar
          </Button>
          <Typography
            variant="caption"
            style={{ fontWeight: 400, color: "#CBCBCB" }}
          >
            Para escolher este produto volte na página de{" "}
            <button
              onClick={onClose}
              style={{
                background: "none",
                border: "none",
                padding: "0",
              }}
            >
              <span style={{ color: vendorTheme?.primaryColor }}>Produtos</span>
            </button>
          </Typography>
        </div>
      }
    />
  );
};
export default ModalInfoMore;
