import styled from "@emotion/styled";

import Content from "components/Content";
import Footer from "components/Footer";
import Header from "components/Header";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext } from "react";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
`;

const HeaderStyles = styled(Header)`
  grid-area: header;
`;

const Container = styled.main<{ backgroundColor: string | undefined }>`
  grid-area: main;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
`;

const FooterStyles = styled(Footer)`
  grid-area: footer;
`;

export type MainProps = {
  children: React.ReactNode;
  className?: string | undefined;
};

const Main = ({ children, className }: MainProps) => {
  const { vendorTheme } = useContext(VendorThemeContext);
  return (
    <Wrapper className={className}>
      <HeaderStyles />
      <Container backgroundColor={vendorTheme?.primaryColor}>
        <Content>{children}</Content>
      </Container>
      <FooterStyles />
    </Wrapper>
  );
};
export default Main;
