import { useContext } from "react";
import { VendorThemeContext } from "contexts/vendorContext";
import styled from "@emotion/styled";
import { breakpoints } from "styles/utils";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${breakpoints.large}) {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 28px;
  }
`;

const LogoKaspersky = styled.img`
  height: 112.63px;
  width: 101.91px;
  @media (min-width: ${breakpoints.small}) {
    height: 135.67px;
    width: 122.49px;
  }
`;

const LogoVendorName = styled.img`
  height: 65.38px;
  @media (min-width: ${breakpoints.medium}) {
    height: 82px;
  }
`;

const VendorHero = () => {
  const { vendorTheme } = useContext(VendorThemeContext);
  return (
    <Container>
      <LogoKaspersky
        loading="lazy"
        alt="logo kaspersky"
        src="images/kaspersky-logo.svg"
      ></LogoKaspersky>
      <LogoVendorName
        loading="lazy"
        alt="logo com o nome do provedor"
        src={vendorTheme?.vendorTitleImage}
      ></LogoVendorName>
    </Container>
  );
};
export default VendorHero;
