import axios from "axios";
import configs from "config";
import { AUTH_USER, saveItemSessionStorage } from "utils/helpers";
import { APIProductProps, ProductProps } from "./products";

export type LoginProps = {
  username: string;
  password: string;
  provedor: string;
  kl_token: string;
};

export type MessageProps = {
  message?: string;
  authenticated: boolean;
};

export type AuthProps = {
  companyId: number;
  userId: number;
  code: number;
  message: string;
  authenticated: boolean;
  products?: ProductProps[];
};

export type APIAuthProps = {
  id_cliente: number;
  id_cliente_usuario: number;
  cod_retorno: number;
  msg_retorno: string;
  autenticado: boolean;
  produtos?: APIProductProps[];
};

const mapperAuth = (apiAuth: APIAuthProps): AuthProps | null => {
  if (!apiAuth) {
    return null;
  }

  return {
    companyId: apiAuth.id_cliente,
    userId: apiAuth.id_cliente_usuario,
    code: apiAuth.cod_retorno,
    message: apiAuth.msg_retorno,
    authenticated: apiAuth.autenticado,
  };
};

const login = async (
  credentials: LoginProps
): Promise<AuthProps | MessageProps | null> => {
  try {
    const response = await axios.post(
      `${configs.baseURL}/Login`,
      {
        username: credentials.username,
        password: credentials.password,
        provedor: credentials.provedor,
      },
      {
        headers: {
          "kl-token": credentials.kl_token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.data && response.data.autenticado) {
      saveItemSessionStorage(`${AUTH_USER}_${credentials.provedor}`, {
        authToken: response.data.autenticado,
        companyId: response.data.id_cliente,
        userId: response.data.id_cliente_usuario,
      });

      const authMapped = mapperAuth(response.data);

      return authMapped;
    } else {
      throw Error(response.data.msg_retorno);
    }
  } catch (error) {
    //@ts-ignore
    const errorMessage = error?.response?.data?.msg_retorno || error?.message;

    return {
      message: errorMessage || "Internal server error",
      authenticated: false,
    };
  }
};

export { login };
