import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import {
  BodyCardSubtitle,
  BodyCardTitle,
} from "components/CardTypography/styles";
import { PaperStyles } from "components/Paper/index.style";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext } from "react";
import { breakpoints } from "styles/utils";

const ContainerCard = styled(PaperStyles)`
  padding: 20px 0px;
  border-radius: 12px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 30px 0px;
  }
`;

const HeaderCard = styled.div<{ backgroundColor?: string | undefined }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  padding: 8px 0px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 20px 0px;
  }
`;

const BodyCard = styled.div`
  padding: 16px 8px;
  @media (min-width: ${breakpoints.small}) {
    padding: 16px 24px;
  }
`;

const WrapperInnerCard = styled(PaperStyles)`
  margin-top: 24px;
  padding: 32px 4px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 32px 16px !important;
    margin-top: 35px !important;
  }

  @media (min-width: ${breakpoints.large}) {
    padding: 50px 60px !important;
  }
`;

const WrapperNoProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (min-width: ${breakpoints.medium}) {
    margin: 50px 16px;
  }
  @media (min-width: ${breakpoints.large}) {
    justify-content: center;
    margin: 85px auto;
    max-width: 800px;
  }
`;

function Help() {
  const { vendorTheme } = useContext(VendorThemeContext);

  return (
    <ContainerCard noPadding elevation={2}>
      <HeaderCard backgroundColor={vendorTheme?.primaryColor}>
        <Typography
          variant="body2"
          align="center"
          variantMapping={{
            h4: "h1",
          }}
          style={{
            fontWeight: "bold",
          }}
        >
          QueroUmAntivírus / Ajuda
        </Typography>
      </HeaderCard>
      <BodyCard>
        <BodyCardTitle variant="h1">
          Dúvidas de Ativação e instalação do produto?
        </BodyCardTitle>
        <BodyCardSubtitle variant="h1">
          Selecionamos alguns links úteis que lhe ajude.
        </BodyCardSubtitle>
        <BodyCardSubtitle variant="h1">
          Caso não encontre o que procura, entre em contato com o nosso suporte
          técnico.
        </BodyCardSubtitle>
        <WrapperInnerCard noPadding noMargin backgroundColor="#F5F5F5">
          <WrapperNoProduct>
            <BodyCardSubtitle variant="h2" align="center">
              Base de Conhecimento Kaspersky para Dispositivos móveis e
              domésticos:
              <div>
                <a
                  href="https://support.kaspersky.com.br/consumer"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.kaspersky.com.br/consumer
                </a>
              </div>
            </BodyCardSubtitle>
            <BodyCardSubtitle variant="h2" align="center">
              Suporte para Produtos Domésticos ou Móveis:
              <div>
                <a
                  href="https://support.kaspersky.com.br/consumer"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.kaspersky.com.br/b2c/BR
                </a>
              </div>
            </BodyCardSubtitle>
            <BodyCardSubtitle variant="h2" align="center">
              Dúvidas sobre ativação de licença ou uso de nossa plataforma
              <div>
                QueroUmAntivirus, envie email para:
                <div>
                  <a href="mailto:queroumantivirus@itailers.com.br">
                    queroumantivirus@itailers.com.br
                  </a>
                </div>
              </div>
            </BodyCardSubtitle>
          </WrapperNoProduct>
        </WrapperInnerCard>
      </BodyCard>
    </ContainerCard>
  );
}

export default Help;
