import styled from "@emotion/styled";
import { breakpoints } from "styles/utils";

const HiddenOnMobile = styled.div`
  display: block;
  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export default HiddenOnMobile;
