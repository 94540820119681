import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { getVendorTheme, VendorTheme } from "theme";
import { Theme } from "@mui/material";
import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import App from "pages/home";
import Login from "pages/login";
import GlobalCss from "styles/global";
import Main from "components/Main";
import Help from "pages/help";
import { VendorThemeContext } from "contexts/vendorContext";
import * as paths from "./paths";
import Products from "pages/products";
import MyAccount from "pages/my-account";
import NotFound from "pages/not-found";
import {
  AUTH_USER,
  getItemSessionStorage,
  THEME_PROVIDER,
  User,
} from "utils/helpers";
import { getTheme } from "services/theme";
import { breakpoints } from "styles/utils";

const getVendorDomainName = () => {
  const urlList = window.location.hostname.split(".");
  if (urlList && urlList[0]) {
    if (
      urlList[0].toUpperCase() === "LOCALHOST" ||
      urlList[0].toUpperCase() === "WHITELABEL-QUEROUMANTIVIRUS"
    ) {
      return "SEA";
    } else {
      return urlList[0].toUpperCase();
    }
  }

  return null;
};

const getAuth = (): User => {
  const hostname = getVendorDomainName();

  const user = getItemSessionStorage(`${AUTH_USER}_${hostname}`) as User;
  return user;
};

const router = createBrowserRouter([
  {
    path: paths.HOME,
    element: (
      <Main>
        <App />
      </Main>
    ),
  },
  {
    path: paths.LOGIN,
    element: (
      <Main>
        <Login />
      </Main>
    ),
    loader: () => {
      const user = getAuth();
      if (!user.authToken) {
        return null;
      }
      return redirect(paths.PRODUCTS);
    },
  },
  {
    path: paths.PRODUCTS,
    element: (
      <Main>
        <Products />
      </Main>
    ),
    loader: () => {
      const user = getAuth();
      if (!user.authToken) {
        return redirect(paths.LOGIN);
      }
      return null;
    },
  },
  {
    path: paths.MY_ACCOUNT,
    element: (
      <Main>
        <MyAccount />
      </Main>
    ),
    loader: () => {
      const user = getAuth();
      if (!user.authToken) {
        return redirect(paths.LOGIN);
      }
      return null;
    },
  },
  {
    path: paths.HELP,
    element: (
      <Main>
        <Help />
      </Main>
    ),
  },
  {
    path: paths.NOT_FOUND,
    element: (
      <Main>
        <NotFound />
      </Main>
    ),
  },
  //if route does not exist send it to home page
  {
    path: "*",
    element: <Navigate replace to="/404"></Navigate>,
  },
]);

const defaultTheme = createTheme({
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: "1.6rem",
      fontWeight: 600,
      [`@media (min-width:${breakpoints.medium})`]: {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      fontWeight: 400,
      [`@media (min-width:${breakpoints.medium})`]: {
        fontSize: "2.0rem",
      },
    },
    h6: {
      fontWeight: 300,
    },
    body2: {
      fontSize: "1.4rem",
      fontWeight: 600,
      [`@media (min-width:${breakpoints.medium})`]: {
        fontSize: "2rem",
      },
    },
    button: {
      fontSize: "1.4rem",
      fontWeight: 400,
      [`@media (min-width:${breakpoints.medium})`]: {
        fontSize: "1.6rem",
      },
    },
    caption: {
      fontSize: "1.2rem",
    },
  },
});

function Router() {
  // Need to customize responsive typography https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme
  //https://mui.com/material-ui/customization/typography/

  const [theme, setTheme] = useState<Theme>(defaultTheme);
  const [vendorTheme, setVendorTheme] = useState<VendorTheme | undefined>();

  const setDefaultTheme = () => {
    const defaultThemeVendor: VendorTheme = {
      primaryColor: "#606060",
      secondaryColor: "#FFFFFF",
      logoImage: "",
      bannerImage: "",
      vendorTitleImage: "",
      isDarkTheme: false, // When true text color should be white
      isDefaultTheme: true,
    };

    //Setting Material UI Theme i.e. Primary Color, Thypography from MUI
    setTheme(defaultTheme);

    //Setting Vendor theme that comes from Quero Um Antivírus API
    //Ex: LogoImage, BannerImage, Vendor Name
    setVendorTheme(defaultThemeVendor);
  };

  const setAPITheme = (apiTheme: VendorTheme) => {
    if (apiTheme) {
      const themeVendor = apiTheme;

      const vendorThemeResponse = getVendorTheme(themeVendor);

      //Setting Material UI Theme i.e. Primary Color, Thypography from MUI
      setTheme(vendorThemeResponse);

      //Setting Vendor theme that comes from Quero Um Antivírus API
      //Ex: LogoImage, BannerImage, Vendor Name
      setVendorTheme(themeVendor);
    }
  };

  useEffect(() => {
    async function fetchTheme() {
      const hostname = getVendorDomainName();

      const savedTheme = getItemSessionStorage(
        `${THEME_PROVIDER}_${hostname}`
      ) as VendorTheme;

      if (!savedTheme) {
        setDefaultTheme();
      }

      //If is null or is default theme should access API to get the theme
      if (!savedTheme || savedTheme.isDefaultTheme) {
        setTimeout(async () => {
          const apiTheme = await getTheme(hostname!);
          setAPITheme(apiTheme);
        }, 1000);
      } else {
        //Get theme from cache
        const vendorThemeResponse = getVendorTheme(savedTheme);
        setTheme(vendorThemeResponse);
        setVendorTheme(savedTheme);
      }
    }
    fetchTheme();
  }, []);

  return (
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <GlobalCss theme={theme} />
        <VendorThemeContext.Provider
          value={{
            vendorTheme,
            setVendorTheme,
          }}
        >
          <RouterProvider router={router} />
        </VendorThemeContext.Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default Router;
