import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import HiddenOnMobile from "components/HiddenOnMobile/index.style";
import GooglePlay from "components/Icons/GooglePlay";
import Iphone from "components/Icons/Iphone";
import MacOS from "components/Icons/MacOS";
import Microsoft from "components/Icons/Microsoft";
import { ProductProps } from "services/products";
import { breakpoints } from "styles/utils";
import Button from "@mui/material/Button";

const WrapperPlatformContent = styled.div`
  @media (min-width: ${breakpoints.large}) {
    margin-top: 100px;
  }
`;

const PlatformCaption = styled(Typography)`
  color: #000;
  font-weight: 400;
  margin-bottom: 16px;
  position: relative;
  &::after {
    content: "";
    border: 1px solid #000000;
    display: flex;
    bottom: -8px;
    position: absolute;
    width: calc(100% + 20px);
    transform: translateX(-10px);
  }
`;

const DesktopInnerCard = styled.div`
  @media (min-width: ${breakpoints.large}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
  }
`;

const ProductDescription = styled(Typography)`
  color: #000;
  margin-bottom: 20px;
  font-weight: 500;
`;

const ProductKey = styled(ProductDescription)`
  border: 1px solid #000000;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  padding: 30px 8px;
  background: #ffffff;

  @media (min-width: ${breakpoints.large}) {
    padding: 24px 12px;
  }
`;

const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.medium}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const GridProduct = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 20px;
`;

const ProductTitle = styled(Typography)`
  color: #000;
  font-weight: 500;
  margin-bottom: 50px;
`;

const MyKasperskyButton = styled(Typography)`
  background-color: black;
  margin: auto;
  color: white;
`;

export type ProductActivatedCardProps = {} & ProductProps;

const ProductActivatedCard = ({
  productImage,
  productName,
  productKey,
  activatedAndroidLink,
  activatedWindowsLink,
  activatedIphoneLink,
  activatedMacLink,
  productActivatedDate,
}: ProductActivatedCardProps) => {
  return (
    <>
      <DesktopInnerCard>
        <GridProduct>
          <img
            height={91}
            width={82}
            loading="lazy"
            alt="logo kaspersky"
            src={productImage}
          ></img>
          <div>
            <ProductTitle variant="h1">{productName}</ProductTitle>
            <ProductDescription variant="h2">
              <b>Data da aquisição:</b>{" "}
              {productActivatedDate &&
                new Date(productActivatedDate).toLocaleDateString("pt-BR")}
            </ProductDescription>
            <ProductDescription variant="h2">
              Se você tiver dúvidas, nosso serviço ao cliente está à sua
              disposição.
            </ProductDescription>
          </div>
        </GridProduct>
        <div>
          <ProductDescription variant="h2">
            Para ativar o produto, você precisa do seu código de acesso pessoal
            abaixo:
          </ProductDescription>
          <ProductKey variant="h2" align="center">
            {productKey}
          </ProductKey>
        </div>
      </DesktopInnerCard>

      <WrapperPlatformContent>
        <ProductDescription variant="h2" align="center">
          Acesse abaixo a plataforma My Kaspersky para ativar sua licença:
        </ProductDescription>

        <ProductDescription variant="h2" align="center">
          
        </ProductDescription>
        <a href="https://my.kaspersky.com" target="_blank">
          <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{ marginTop: 32 }}
            >
              My kaspersky
            </Button>
        </a>
      </WrapperPlatformContent>
    </>
  );
};
export default ProductActivatedCard;
