import axios from "axios";
import configs from "config";

export type MessageProps = {
  message?: string;
};

export type APIProductProps = {
  nome_produto: string;
  urn_produto: string;
  link_ativacao_android: string;
  link_ativacao_windows: string;
  link_ativacao_iphone: string;
  link_ativacao_mac: string;
  chave_ativacao: string;
  cd_produto: string;
  data_ativacao: string;
  imagem_produto: string;
  descricao: string;
  ativado?: boolean;
  id_produto: string;
};

export type ProductProps = {
  productName: string;
  productId: string;
  productImage: string;
  description: string;
  activated?: boolean;
  productKey?: string;
  productActivatedDate?: string;
  activatedAndroidLink?: string;
  activatedWindowsLink?: string;
  activatedIphoneLink?: string;
  activatedMacLink?: string;
};

export const mapperProducts = (
  apiProducts: APIProductProps[]
): ProductProps[] | [] => {
  if (!apiProducts.length) {
    return [];
  }

  return apiProducts.map((item) => ({
    productName: item.nome_produto,
    productId: item.id_produto,
    productImage: item.imagem_produto,
    description: item.descricao,
    productActivatedDate: item.data_ativacao,
    productKey: item.chave_ativacao,
    activated: item.ativado,
    activatedAndroidLink: item.link_ativacao_android,
    activatedWindowsLink: item.link_ativacao_windows,
    activatedIphoneLink: item.link_ativacao_iphone,
    activatedMacLink: item.link_ativacao_mac,
  }));
};

const getProducts = async (
  userId: number,
  kl_token: string
): Promise<ProductProps[] | MessageProps> => {
  try {
    const response = await axios.post(
      `${configs.baseURL}/GetProdutos`,
      {
        id_cliente_usuario: userId,
      },
      {
        headers: {
          "kl-token": kl_token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.data && response.data.produtos.length) {
      const productsMapped = mapperProducts(response.data.produtos);

      return productsMapped;
    } else {
      throw Error(response.data.msg_retorno);
    }
  } catch (error) {
    //@ts-ignore
    const errorMessage = error?.response?.data?.msg_retorno || error?.message;

    return {
      message: errorMessage || "Internal server error",
    };
  }
};

export type ErrorProps = {
  message?: string;
  activated?: boolean;
};

export type ActivateProductProducts = {
  productId: ProductProps["productId"];
  userId: number;
  kl_token: string;
  companyId: number;
};

const activateProduct = async (
  activadeProduct: ActivateProductProducts
): Promise<ProductProps | ErrorProps> => {
  try {
    const response = await axios.post(
      `${configs.baseURL}/AtivarLicenca`,
      {
        id_cliente: activadeProduct.companyId,
        id_cliente_usuario: activadeProduct.userId,
        id_produto: activadeProduct.productId,
      },
      {
        headers: {
          "kl-token": activadeProduct.kl_token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response && response.data && response.data.produtos.length > 0) {
      return {
        activated: true,
        message: response.data.msg_retorno,
      };
    } else {
      throw Error(response.data.msg_retorno);
    }
  } catch (error) {
    //@ts-ignore
    const errorMessage = error?.response?.data?.msg_retorno || error?.message;

    return {
      activated: false,
      message: errorMessage || "Internal server error",
    };
  }
};
export { getProducts, activateProduct };
