import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import Content from "components/Content";
import VendorLogo from "components/VendorLogo";

import * as paths from "router/paths";
import { breakpoints } from "styles/utils";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext } from "react";
import {
  AUTH_USER,
  clearSessionStorage,
  getItemSessionStorage,
  User,
} from "utils/helpers";

const ButtonLink = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666666;
`;

const Ul = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContainerHeader = styled.header`
  background-color: #fff;
  padding: 4px;
  @media (min-width: ${breakpoints.medium}) {
    padding: 12px;
  }
  @media (min-width: ${breakpoints.large}) {
    padding: 12px 0px;
  }
`;

const Header = ({ className }: { className?: string | undefined }) => {
  const { vendorTheme } = useContext(VendorThemeContext);
  const user = getItemSessionStorage(
    `${AUTH_USER}_${vendorTheme?.vendorDomainName}`
  ) as User;
  const navigate = useNavigate();

  return (
    <ContainerHeader className={className}>
      <Content>
        <nav>
          {!vendorTheme || vendorTheme?.isDefaultTheme ? (
            <div style={{ height: 70 }}></div>
          ) : (
            <Ul>
              <li>
                <Link to={paths.HOME}>
                  <VendorLogo></VendorLogo>
                </Link>
              </li>
              <div style={{ display: "flex" }}>
                {!user.authToken && (
                  <li>
                    <Link to={paths.LOGIN}>
                      <ButtonLink>
                        <LoginIcon />
                        <Typography
                          style={{
                            color: "#606060",
                            textTransform: "capitalize",
                          }}
                        >
                          Entrar
                        </Typography>
                      </ButtonLink>
                    </Link>
                  </li>
                )}
                {user.authToken && (
                  <li>
                    <Link to={paths.MY_ACCOUNT}>
                      <ButtonLink>
                        <AccountCircleIcon />
                        <Typography
                          style={{
                            color: "#606060",
                            textTransform: "capitalize",
                          }}
                        >
                          Perfil
                        </Typography>
                      </ButtonLink>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={paths.HELP}>
                    <ButtonLink>
                      <HelpIcon />
                      <Typography
                        style={{
                          color: "#606060",
                          textTransform: "capitalize",
                        }}
                      >
                        Ajuda
                      </Typography>
                    </ButtonLink>
                  </Link>
                </li>
                {user.authToken && (
                  <li>
                    <ButtonLink
                      onClick={() => {
                        clearSessionStorage();
                        navigate(paths.HOME);
                      }}
                    >
                      <LogoutIcon />
                      <Typography
                        style={{
                          color: "#606060",
                          textTransform: "capitalize",
                        }}
                      >
                        Logout
                      </Typography>
                    </ButtonLink>
                  </li>
                )}
              </div>
            </Ul>
          )}
        </nav>
      </Content>
    </ContainerHeader>
  );
};
export default Header;
