import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import Content from "components/Content";
import VendorLogo from "components/VendorLogo";

import * as paths from "router/paths";
import { breakpoints } from "styles/utils";
import HiddenOnDesktop from "components/HiddenOnDesktop/index.style";
import HiddenOnMobile from "components/HiddenOnMobile/index.style";
import { useContext } from "react";
import { VendorThemeContext } from "contexts/vendorContext";
import {
  AUTH_USER,
  getItemSessionStorage,
  removeItemSessionStorage,
  User,
} from "utils/helpers";

const ContainerFooter = styled.footer`
  background-color: #fff;
  padding: 4px;

  @media (min-width: ${breakpoints.medium}) {
    padding: 12px;
  }
  @media (min-width: ${breakpoints.large}) {
    padding: 12px 0px;
  }
`;

const FooterUl = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;

  @media (min-width: ${breakpoints.medium}) {
    grid-gap: 20px;
  }

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ColumnAlignRight = styled.div`
  justify-self: end;

  display: flex;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: #666666;
`;

const OficialDistributor = styled(Typography)`
  font-weight: 400;
  width: 128px;
  margin-bottom: 12px;
  font-size: 1.4rem !important;
  text-align: center !important;
`;

const DescriptionFooter = styled(Typography)`
  font-size: 1rem;
  @media (min-width: ${breakpoints.medium}) {
    font-size: 1.6rem;
  }
`;

const LogoItaliers = styled.img`
  height: 30px;
  width: 80px;
  @media (min-width: ${breakpoints.medium}) {
    height: 44px;
    width: 115px;
  }
`;

const ContianerFooterLogo = styled.div`
  @media (max-width: ${breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Footer = ({ className }: { className?: string | undefined }) => {
  const navigate = useNavigate();
  const { vendorTheme } = useContext(VendorThemeContext);

  const user = getItemSessionStorage(
    `${AUTH_USER}_${vendorTheme?.vendorDomainName}`
  ) as User;

  return (
    <ContainerFooter className={className}>
      <Content>
        <FooterContent>
          <HiddenOnDesktop>
            {!vendorTheme || vendorTheme?.isDefaultTheme ? (
              <div></div>
            ) : (
              <NavLink to={paths.HOME}>
                <VendorLogo />
              </NavLink>
            )}

            <DescriptionFooter
              variant="caption"
              color="#666666"
              align="left"
              style={{ marginTop: 16 }}
            >
              <div style={{ display: "block" }}>
                A Kaspersky Lab é uma empresa internacional de segurança virtual
                fundada em 1997
              </div>
              <div style={{ display: "block" }}>
                Itailers Distribuidora de Software Ltda CNPJ40.842.134/0001-74
              </div>

              <div style={{ display: "block" }}>
                <a href="mailto:queroumantivirus@itailers.com.br">
                  queroumantivirus@itailers.com.br
                </a>
              </div>
            </DescriptionFooter>
          </HiddenOnDesktop>
          <HiddenOnMobile>
            <nav>
              <FooterUl>
                {!vendorTheme || vendorTheme?.isDefaultTheme ? (
                  <div></div>
                ) : (
                  <>
                    <li>
                      <NavLink to={paths.HOME}>
                        <VendorLogo />
                      </NavLink>
                    </li>
                    {!user.authToken && (
                      <li>
                        <NavLink to={paths.LOGIN}>
                          <Typography style={{ color: "#606060" }}>
                            Entrar
                          </Typography>
                        </NavLink>
                      </li>
                    )}

                    {user.authToken && (
                      <li>
                        <NavLink to={paths.MY_ACCOUNT}>
                          {" "}
                          <Typography style={{ color: "#606060" }}>
                            Perfil
                          </Typography>
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink to={paths.HELP}>
                        <Typography style={{ color: "#606060" }}>
                          Ajuda{" "}
                        </Typography>
                      </NavLink>
                    </li>
                    {user.authToken && (
                      <li>
                        <button
                          onClick={() => {
                            removeItemSessionStorage(AUTH_USER);
                            navigate(paths.HOME);
                          }}
                          style={{
                            background: "none",
                            border: "none",
                            padding: "0",
                            color: "#666666",
                            cursor: "pointer",
                          }}
                        >
                          <Typography>Logout</Typography>
                        </button>
                      </li>
                    )}
                  </>
                )}
              </FooterUl>
            </nav>
          </HiddenOnMobile>
          <HiddenOnMobile
            style={{
              alignSelf: "center",
            }}
          >
            <ColumnAlignRight>
              <Typography variant="caption" color="#666666" align="center">
                <div style={{ display: "block" }}>
                  A Kaspersky Lab é uma empresa internacional de segurança
                  virtual fundada em 1997
                </div>
                <div style={{ display: "block" }}>
                  Itailers Distribuidora de Software Ltda CNPJ40.842.134/0001-74
                </div>

                <div style={{ display: "block" }}>
                  <a href="mailto:queroumantivirus@itailers.com.br">
                    queroumantivirus@itailers.com.br
                  </a>
                </div>
              </Typography>
            </ColumnAlignRight>
          </HiddenOnMobile>
          <ColumnAlignRight>
            <ContianerFooterLogo>
              <OficialDistributor variant="body2" color="#666666">
                Distribuidor Oficial Kaspersky
              </OficialDistributor>
              <LogoItaliers
                loading="lazy"
                alt="Logo Italiers"
                src="/images/itailers-logo.svg"
              />
            </ContianerFooterLogo>
          </ColumnAlignRight>
        </FooterContent>
      </Content>
    </ContainerFooter>
  );
};
export default Footer;
