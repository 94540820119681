import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import { VendorThemeContext } from "contexts/vendorContext";

import { MY_ACCOUNT } from "router/paths";
import BaseModal from ".";
import { ProductProps } from "services/products";

export type ActivationStatus = "initial" | "success" | "error";

export type ModalActivitationProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onError: () => void;
  status: ActivationStatus;
  loading: boolean;
} & Pick<ProductProps, "productId" | "productName">;

const ModalActivitation = ({
  open,
  status,
  onClose,
  onConfirm,
  onError,
  productId,
  productName,
  loading,
}: ModalActivitationProps) => {
  const navigate = useNavigate();

  const { vendorTheme } = useContext(VendorThemeContext);

  let renderTitle: React.ReactNode = null;
  let renderBody: React.ReactNode = null;
  let renderFooter: React.ReactNode = null;

  if (status === "initial") {
    renderTitle = (
      <Typography
        variant="h5"
        color="primary"
        style={{ fontWeight: 400, color: "#000" }}
      >
        Você escolheu o
      </Typography>
    );
    renderBody = (
      <div>
        <Typography
          variant="h6"
          style={{
            fontWeight: 300,
            color: "#000",
            padding: "16px 30px",
            border: "1px solid #B5B5B5",
            borderRadius: 10,
            marginBottom: 20,
          }}
          align="center"
        >
          {productName}
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          style={{ fontWeight: 400, color: "#000" }}
        >
          Ao escolher este produto, poderá desabilitar a opção da escolha de
          outros. Esta execução é irreversível. Clique no botão abaixo para
          confirmar.
        </Typography>
      </div>
    );
    renderFooter = (
      <div style={{ width: "100%" }}>
        <Button
          fullWidth
          variant="contained"
          style={{ marginTop: 100, height: 60 }}
          onClick={onConfirm}
          disabled={loading}
        >
          {loading ? "Ativando..." : "Confirmar"}
        </Button>
        <Typography
          variant="caption"
          style={{ fontWeight: 400, color: "#CBCBCB" }}
        >
          Para realizar o download e acesso aos serviços acesse{" "}
          <Link to={MY_ACCOUNT}>
            <span style={{ color: vendorTheme?.primaryColor }}>Meu perfil</span>
          </Link>
        </Typography>
      </div>
    );
  }

  if (status === "success") {
    renderTitle = (
      <Typography
        variant="h5"
        color="primary"
        style={{ fontWeight: 400, color: "#000" }}
      >
        Parabéns!
      </Typography>
    );
    renderBody = (
      <Typography
        color="primary"
        variant="subtitle1"
        style={{ fontWeight: 400, color: "#000" }}
      >
        Você fez uma ótima escolha! Para ter acesso ao seu benefício acesse “Meu
        perfil” e siga as instruções.
      </Typography>
    );
    renderFooter = (
      <div style={{ width: "100%" }}>
        <Button
          fullWidth
          variant="contained"
          style={{ marginTop: 100, height: 60 }}
          onClick={() => {
            navigate(MY_ACCOUNT);
          }}
        >
          Meu Perfil
        </Button>
        <Typography
          variant="caption"
          style={{ fontWeight: 400, color: "#CBCBCB" }}
        >
          Para realizar o download e acesso aos serviços acesse{" "}
          <Link to={MY_ACCOUNT}>
            <span style={{ color: vendorTheme?.primaryColor }}>Meu perfil</span>
          </Link>
        </Typography>
      </div>
    );
  }

  if (status === "error") {
    renderTitle = (
      <Typography
        variant="h5"
        color="primary"
        style={{ fontWeight: 400, color: "#000" }}
      >
        OPS!
      </Typography>
    );
    renderBody = (
      <Typography
        color="primary"
        variant="subtitle1"
        style={{ fontWeight: 400, color: "#000" }}
      >
        Algo deu errado. Tente novamente mais tarde ou entre em contato com o
        nosso suporte em:{" "}
        <a href="mailto:queroumantivirus@itailers.com.br">
          queroumantivirus@itailers.com.br
        </a>
      </Typography>
    );
    renderFooter = (
      <div style={{ width: "100%" }}>
        <Button
          fullWidth
          variant="contained"
          style={{ marginTop: 100, height: 60 }}
          onClick={onError}
        >
          Voltar
        </Button>
        <Typography
          variant="caption"
          style={{ fontWeight: 400, color: "#CBCBCB" }}
        >
          Para realizar o download e acesso aos serviços acesse{" "}
          <Link to={MY_ACCOUNT}>
            <span style={{ color: vendorTheme?.primaryColor }}>Meu perfil</span>
          </Link>
        </Typography>
      </div>
    );
  }

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={renderTitle}
      body={renderBody}
      footer={renderFooter}
    />
  );
};

export default ModalActivitation;
