export const AUTH_USER = "AUTH_USER";
export const THEME_PROVIDER = "THEME_PROVIDER";
export const PRODUCTS_PROVIDER = "PRODUCTS_PROVIDER";

export type User = {
  authToken: string;
};

export const saveItemSessionStorage = (key: string, value: unknown) => {
  try {
    sessionStorage.setItem(key?.toUpperCase(), JSON.stringify(value));
  } catch (error) {
    console.log("save method should be used in client code");
  }
};

export const getItemSessionStorage = (key: string) => {
  try {
    let item: unknown = "";

    if (sessionStorage.getItem(key?.toUpperCase())) {
      item = JSON.parse(sessionStorage.getItem(key?.toUpperCase()) || "");
    }
    return item;
  } catch (error) {
    console.log("save method should be used in client code");
  }
};

export const removeItemSessionStorage = (key: string) => {
  sessionStorage.removeItem(key?.toUpperCase());
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
