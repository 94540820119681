import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";

import styled from "@emotion/styled";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { PaperStyles } from "components/Paper/index.style";
import VendorHero from "components/VendorHero";
import { VendorThemeContext } from "contexts/vendorContext";
import { breakpoints } from "styles/utils";
import Spinner from "components/Spinner";
import { PRODUCTS } from "router/paths";
import { login } from "services/login";
import { Typography } from "@mui/material";

const EmailField = styled(TextField)`
  // & input:-internal-autofill-selected {
  //   appearance: unset !important;
  //   background-image: unset !important;
  //   background-color: unset !important;
  //   color: unset !important;
  // }
  // & input:-webkit-autofill {
  //   appearance: unset !important;
  //   background-image: unset !important;
  //   background-color: unset !important;
  //   color: unset !important;
  // }
`;

const PaperContainer = styled(PaperStyles)`
  padding: 100px 16px;
  @media (min-width: ${breakpoints.large}) {
    width: 1170px;
    padding-bottom: 230px;
  }
`;

const Content = styled.section`
  @media (min-width: ${breakpoints.small}) {
    width: 300px;
    margin: 0 auto;
  }
`;

const validationSchema = object({
  username: string().required("Usuário é obrigatório"),
  password: string().required("Senha é obrigatório"),
});

const Login = () => {
  const navigate = useNavigate();
  const { vendorTheme } = useContext(VendorThemeContext);
  const [error, setError] = useState<{ message?: string } | undefined>(
    undefined
  );

  const handleSubmit = async (values: any) => {
    setError(undefined);
    const auth = await login({
      ...values,
      provedor: vendorTheme?.vendorDomainName?.toUpperCase()!,
      kl_token: vendorTheme?.kl_token!,
    });

    if (!auth?.authenticated) {
      setError({ message: auth?.message });
    } else {
      formik.resetForm();
      setError(undefined);
      navigate(PRODUCTS);
    }
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <PaperContainer backgroundColor={vendorTheme?.secondaryColor}>
      <Content>
        <VendorHero />
        <form onSubmit={formik.handleSubmit} style={{ marginTop: 28 }}>
          <EmailField
            style={{ marginBottom: 12 }}
            variant="outlined"
            fullWidth
            id="username"
            name="username"
            label="Usuário"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            variant="outlined"
            fullWidth
            id="password"
            name="password"
            label="Senha"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            style={{ marginTop: 32 }}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? <Spinner /> : "Entrar"}
          </Button>
        </form>
        {error && (
          <Typography variant="body1" style={{ marginTop: 24, color: "red" }}>
            {error?.message}
          </Typography>
        )}
      </Content>
    </PaperContainer>
  );
};

export default Login;
