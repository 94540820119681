import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const SpinnerLoading = styled(Typography)<{ size?: number }>`
  diplay: block;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 2px solid currentColor;
  border-top: 2px solid #6666;

  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  -webkit-animation: loading 2s linear infinite; /* Safari */
  animation: loading 2s linear infinite;

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({ size = 30 }: { size?: number }) => {
  return <SpinnerLoading size={size}></SpinnerLoading>;
};
export default Spinner;
