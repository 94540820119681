import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import ModalActivitation, {
  ActivationStatus,
} from "components/Modal/ModalActivation";
import ModalInfoMore from "components/Modal/ModalInfoMore";
import { PaperStyles } from "components/Paper/index.style";
import { VendorThemeContext } from "contexts/vendorContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MY_ACCOUNT } from "router/paths";
import { AuthProps } from "services/login";
import { activateProduct, ProductProps } from "services/products";
import { breakpoints } from "styles/utils";
import { AUTH_USER, getItemSessionStorage } from "utils/helpers";

const CardButton = styled(Button)`
  width: calc(100% - 32px);

  @media (min-width: ${breakpoints.medium}) {
    max-width: 390px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  margin: 16px auto;
  justify-items: center;
  gap: 16px 0px;
  @media (min-width: ${breakpoints.medium}) {
    margin: 32px auto;
    gap: 32px 0px;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #000;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const KasperskyLogoImage = styled.img`
  height: 111px;
  width: 123px;
  margin: 50px;
`;

const BodyTitle = styled(Typography)`
  font-weight: 600;
  color: #000;
`;

const BodyDescription = styled.div`
  display: flex;
`;

const BodyPlatformItem = styled(Typography)`
  color: #000;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const FooterCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ProductCard = ({
  productName,
  productId,
  description,
  productImage,
  activated,
}: ProductProps) => {
  const [loading, setLoading] = useState(false);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const [statusActivivation, setStatusActivivation] =
    useState<ActivationStatus>("initial");
  const { vendorTheme } = useContext(VendorThemeContext);
  const navigate = useNavigate();

  const handleOpenActivationModal = () => {
    setOpenActivationModal(true);
  };

  const handleCloseActivationModal = () => {
    setOpenActivationModal(false);

    setTimeout(() => {
      if (statusActivivation === "success") {
        navigate(MY_ACCOUNT);
      }
      setStatusActivivation("initial");
    }, 500);
  };

  const handleOpenMoreInfoModal = () => {
    setOpenMoreInfoModal(true);
  };

  const handleCloseMoreInfoModal = () => {
    setOpenMoreInfoModal(false);
  };

  const handleActivation = async () => {
    setLoading(true);
    const auth = getItemSessionStorage(
      `${AUTH_USER}_${vendorTheme?.vendorDomainName}`
    ) as AuthProps;

    const responseProduct = await activateProduct({
      productId: productId,
      kl_token: vendorTheme?.kl_token!,
      companyId: vendorTheme?.id_cliente!,
      userId: auth.userId,
    });

    if (responseProduct.activated) {
      setStatusActivivation("success");
    } else {
      setStatusActivivation("error");
    }

    setLoading(false);
  };

  const ContainerCard = styled(PaperStyles)`
    padding: 20px 0px;
    border-radius: 12px;

    @media (min-width: ${breakpoints.medium}) {
      padding: 30px 0px;
    }
  `;

  const HeaderCard = styled.div<{ backgroundColor?: string | undefined }>`
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: center;

    @media (min-width: ${breakpoints.medium}) {
      padding: 20px 0px;
    }
  `;

  const BodyCard = styled.div`
    padding: 30px;
    background: #f5f5f5;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.17);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 266px;
    @media (min-width: ${breakpoints.medium}) {
      max-width: 335px;
    }
  `;

  return (
    <ContainerCard noPadding elevation={2}>
      <HeaderCard backgroundColor={vendorTheme?.primaryColor}>
        <Typography
          variant="body2"
          align="center"
          variantMapping={{
            button: "h1",
          }}
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "8px 0px",
          }}
        >
          {productName}
        </Typography>
      </HeaderCard>
      <Cards>
        <BodyCard>
          <BodyTitle align="center" variant="h1">
            {productName}
          </BodyTitle>
          <KasperskyLogoImage
            alt="logo kaspersky"
            src={productImage}
          ></KasperskyLogoImage>
          <BodyDescription>
            <BodyPlatformItem variant="caption">Android</BodyPlatformItem>
            <BodyPlatformItem variant="caption">iOS</BodyPlatformItem>
            <BodyPlatformItem variant="caption">Mac</BodyPlatformItem>
            <BodyPlatformItem variant="caption">Windows</BodyPlatformItem>
          </BodyDescription>
          <Divider></Divider>
          <Button variant="contained" onClick={handleOpenMoreInfoModal}>
            Saiba Mais
          </Button>
          <ModalInfoMore
            open={openMoreInfoModal}
            onClose={handleCloseMoreInfoModal}
            productImage={productImage}
            productName={productName}
            description={description}
          />
        </BodyCard>
      </Cards>
      <FooterCard>
        {activated ? (
          <CardButton variant="contained" onClick={() => navigate(MY_ACCOUNT)}>
            IR PARA MINHA LICENÇA
          </CardButton>
        ) : (
          <CardButton variant="contained" onClick={handleOpenActivationModal}>
            Selecionar este produto
          </CardButton>
        )}

        <ModalActivitation
          productName={productName}
          productId={productId}
          status={statusActivivation}
          open={openActivationModal}
          onClose={handleCloseActivationModal}
          onError={handleCloseActivationModal}
          onConfirm={handleActivation}
          loading={loading}
        />
      </FooterCard>
    </ContainerCard>
  );
};

export default ProductCard;
